import React from 'react';

import { Form, Formik } from 'formik';

import { Button } from '@components/Button';
import { FormElementErrorMessage } from '@components/Forms/FormElement';
import ContactDetails from '@presenters/web/components/Leads/Forms/ContactDetails';
import { personalDetailsValidationSchema } from '@presenters/web/pages/Leads/validationSchema';

import {
  getFormInformation,
  getFormInitialValue,
} from '@domain/leads/join-form/formInfo';
import { FormFieldsProps, FormValues } from '@domain/leads/types';

import { useTranslation } from '@external/react-i18next';

const ContactInformation: React.FC<{ submitHandler: () => void }> = ({
  submitHandler,
}) => {
  const { t } = useTranslation();
  const fieldProps: FormFieldsProps = getFormInformation(
    t,
    'contactInformation'
  );
  const fieldInitialValue: FormValues = getFormInitialValue(
    'contactInformation'
  );

  return (
    <Formik
      initialValues={fieldInitialValue}
      onSubmit={submitHandler}
      validateOnBlur
      validationSchema={personalDetailsValidationSchema(t)}
    >
      {({ isValid, submitCount }) => {
        return (
          <Form>
            <div className="desktop:flex desktop:flex-row-reverse mt-10">
              <div className="desktop:flex-2 desktop:mr-24">
                <div className="max-w-lg">
                  <ContactDetails fieldProps={fieldProps} />
                </div>
              </div>
            </div>
            {!isValid && !!submitCount && (
              <div className="mb-4">
                <FormElementErrorMessage id="global">
                  {t(
                    'contact-information.details_form.errors',
                    'The form contains errors. Please review and correct them first.'
                  )}
                </FormElementErrorMessage>
              </div>
            )}
            <div className="max-w-lg mb-20 mt-8">
              <Button type="submit" full>
                {t('join-contact-details.form.continue-label', 'Continue')}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ContactInformation;
