import React, { useRef } from 'react';

import { Form, Formik } from 'formik';

import { Button } from '@components/Button';
import { FormElementErrorMessage } from '@components/Forms/FormElement';
import AddressForm from '@presenters/web/components/Leads/Forms/AddressForm';
import MoreInformation from '@presenters/web/components/Leads/Forms/MoreInformation';
import { addressInformationValidationSchema } from '@presenters/web/pages/Leads/validationSchema';

import {
  getFormInformation,
  getFormInitialValue,
} from '@domain/leads/join-form/formInfo';
import { AddressProps, FormFieldsProps, FormValues } from '@domain/leads/types';

import { useRecaptcha } from '@use-cases/account/hook';

import { useTranslation } from '@external/react-i18next';

const ClubMeetingPreferences: React.FC<{
  submitHandler: () => void;
}> = ({ submitHandler }) => {
  const { t } = useTranslation();
  const fieldProps: FormFieldsProps = getFormInformation(
    t,
    'clubMeetingPreferences'
  );
  const fieldInitialValue: FormValues = getFormInitialValue(
    'clubMeetingPreferences'
  );
  const recaptchaRef = useRef(null);
  const { onRecaptchaChange, onRecaptchaExpired, resetCaptcha } = useRecaptcha(
    recaptchaRef
  );
  const clubMeetingPreferencesFormSubmit = () => {
    resetCaptcha();
    submitHandler();
  };

  return (
    <>
      <h3 className="font-normal mt-6 mb-2">
        {t(
          'join-form-referral.sub_head_club_meeting_preferences',
          'Club meeting preferences'
        )}
      </h3>
      <p className="desktop:mb-0">
        {t(
          'club-meeting-preference.title.info',
          'We will do our best to match you with a club near you, with a meeting time that fits your schedule.'
        )}
      </p>
      <Formik
        initialValues={fieldInitialValue}
        onSubmit={clubMeetingPreferencesFormSubmit}
        validationSchema={addressInformationValidationSchema(t)}
      >
        {({
          values,
          isValid,
          submitCount,
          isSubmitting,
          setFieldValue,
          dirty,
        }) => {
          const { interestGroupId } = values;
          const { countryId } = values.primaryAddress as AddressProps;
          const onChangeCaptcha = (reToken: string | null) => {
            setFieldValue('recaptcha', reToken);
            onRecaptchaChange(reToken);
          };
          const onExpiredCaptcha = () => {
            setFieldValue('recaptcha', '');
            onRecaptchaExpired();
          };
          return (
            <Form>
              <AddressForm
                countryId={countryId as string}
                fieldProps={fieldProps}
              />
              <MoreInformation
                interestGroupId={interestGroupId as string}
                recaptchaRef={recaptchaRef}
                onChangeCaptcha={onChangeCaptcha}
                onExpiredCaptcha={onExpiredCaptcha}
              />
              <div className="desktop:flex-row max-w-lg mt-6 desktop:mt-10 mb-6 desktop:mb-10">
                {!isValid && !!submitCount && (
                  <div className="mb-4">
                    <FormElementErrorMessage id="global">
                      {t(
                        'club_metting_preference.details_form.errors',
                        'The form contains errors. Please review and correct them first.'
                      )}
                    </FormElementErrorMessage>
                  </div>
                )}
                <Button
                  type="submit"
                  full
                  disabled={isSubmitting || !(isValid && dirty)}
                >
                  {t('club-meeting-preference.form.submit-label', 'Submit')}
                </Button>
                <Button full text type="button" className="mt-6">
                  {t('club-meeting-preference.form.cancel-label', 'Cancel')}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ClubMeetingPreferences;
