import { TFunction } from 'i18next';

export const getListSourceGroups = (t: TFunction) => {
  return [
    {
      id: 'personal-connect',
      name: t(
        'join-form.select.option1',
        'Through a personal connection (friend or family)'
      ),
    },
    {
      id: 'media',
      name: t('join-form.select.option2', 'Through the media'),
    },
    {
      id: 'program',
      name: t('join-form.select.option3', 'I participated in a Rotary Program'),
    },
    {
      id: 'event',
      name: t('join-form.select.option4', 'I attended a Rotary event'),
    },
    {
      id: 'toastmasters',
      name: t('join-form.select.option5', 'Through Toastmasters'),
    },
  ];
};

export const getListSource = (t: TFunction) => {
  return {
    event: [
      {
        id: 'join-form.select.option6',
        name: t('join-form.select.option6', 'Rotary Convention'),
      },
      {
        id: 'join-form.select.option7',
        name: t('join-form.select.option7', 'World Polio Day Livestream event'),
      },
      {
        id: 'join-form.select.option8',
        name: t(
          'join-form.select.option8',
          'An event held by another organization where Rotary was represented'
        ),
      },
      {
        id: 'join-form.select.option9',
        name: t(
          'join-form.select.option9',
          'Rotary club-led community activity'
        ),
      },
      {
        id: 'join-form.select.option10',
        name: t('join-form.select.option10', 'International Week of Service'),
      },
      {
        id: 'join-form.select.option11',
        name: t('join-form.select.option11', 'Other'),
      },
    ],
    media: [
      {
        id: 'join-form.select.option12',
        name: t('join-form.select.option12', 'TV'),
      },
      {
        id: 'join-form.select.option13',
        name: t('join-form.select.option13', 'Radio'),
      },
      {
        id: 'join-form.select.option14',
        name: t('join-form.select.option14', 'Newspaper'),
      },
      {
        id: 'join-form.select.option15',
        name: t('join-form.select.option15', 'Magazine'),
      },
      {
        id: 'join-form.select.option16',
        name: t(
          'join-form.select.option16',
          'Outdoor advertising (billboard, moving sign, bus stop ads,...)'
        ),
      },
      {
        id: 'join-form.select.option17',
        name: t(
          'join-form.select.option17',
          'Social media (Facebook, Twitter,...)'
        ),
      },
      {
        id: 'join-form.select.option18',
        name: t('join-form.select.option18', 'Internet search'),
      },
      {
        id: 'join-form.select.option19',
        name: t('join-form.select.option19', 'Landmark Illumination'),
      },
      {
        id: 'join-form.select.option20',
        name: t(
          'join-form.select.option20',
          'This Close or Polio Ambassador ad'
        ),
      },
      {
        id: 'join-form.select.option21',
        name: t('join-form.select.option21', 'End Polio Now ad'),
      },
      {
        id: 'join-form.select.option22',
        name: t('join-form.select.option22', 'Other'),
      },
    ],
    program: [
      {
        id: 'join-form.select.option23',
        name: t('join-form.select.option23', 'Interact'),
      },
      {
        id: 'join-form.select.option24',
        name: t('join-form.select.option24', 'Rotaract'),
      },
      {
        id: 'join-form.select.option25',
        name: t(
          'join-form.select.option25',
          'Rotary Youth Leadership Awards (RYLA)'
        ),
      },
      {
        id: 'join-form.select.option26',
        name: t('join-form.select.option26', 'Rotary Youth Exchange'),
      },
      {
        id: 'join-form.select.option27',
        name: t(
          'join-form.select.option27',
          'New Generations Service Exchange'
        ),
      },
      {
        id: 'join-form.select.option28',
        name: t('join-form.select.option28', 'Group Study Exchange'),
      },
      {
        id: 'join-form.select.option29',
        name: t('join-form.select.option29', 'Vocational Training Teams'),
      },
      {
        id: 'join-form.select.option30',
        name: t('join-form.select.option30', 'Rotary Volunteers'),
      },
      {
        id: 'join-form.select.option31',
        name: t('join-form.select.option31', 'Ambassadorial Scholarships'),
      },
      {
        id: 'join-form.select.option32',
        name: t('join-form.select.option32', 'Club scholarships'),
      },
      {
        id: 'join-form.select.option33',
        name: t('join-form.select.option33', 'Rotary scholarships'),
      },
      {
        id: 'join-form.select.option34',
        name: t('join-form.select.option34', 'GRSP'),
      },
      {
        id: 'join-form.select.option35',
        name: t('join-form.select.option35', 'Yoneyama'),
      },
      {
        id: 'join-form.select.option36',
        name: t('join-form.select.option36', 'Grants for University Teachers'),
      },
      {
        id: 'join-form.select.option37',
        name: t('join-form.select.option37', 'Rotary Peace Fellowships'),
      },
      {
        id: 'join-form.select.option38',
        name: t('join-form.select.option38', 'Other'),
      },
    ],
    'personal-connect': [
      {
        id: 'join-form.select.option39',
        name: t('join-form.select.option39', 'Parent'),
      },
      {
        id: 'join-form.select.option40',
        name: t('join-form.select.option40', 'Child'),
      },
      {
        id: 'join-form.select.option41',
        name: t('join-form.select.option41', 'Spouse/Partner'),
      },
      {
        id: 'join-form.select.option42',
        name: t('join-form.select.option42', 'Sibling'),
      },
      {
        id: 'join-form.select.option43',
        name: t('join-form.select.option43', 'Friend'),
      },
      {
        id: 'join-form.select.option44',
        name: t('join-form.select.option44', 'Sponsor'),
      },
      {
        id: 'join-form.select.option45',
        name: t('join-form.select.option45', 'Other'),
      },
    ],
    toastmasters: [],
  };
};
