import React from 'react';

import { useFormikContext } from 'formik';
import { TFunction } from 'i18next';

import Select, { SelectProps } from '@components/Formik/Select';

import { getListSourceGroups } from '@domain/leads/join-form/interestList';
import { InterestGroup } from '@domain/leads/join-form/types';

import { useTranslation } from '@external/react-i18next';

type Props = Omit<SelectProps, 'options'> & {
  name: string;
  sourceName: string;
};

const generateOptions = (groups: InterestGroup[]) =>
  groups.map(({ name, id }) => ({ label: name, value: id }));

const getInterestSourceGroups = (t: TFunction) => {
  const listInterestGroup: Array<InterestGroup> = getListSourceGroups(t);

  return generateOptions(listInterestGroup);
};

const InterestGroupSelect: React.FC<Props> = props => {
  const { name: groupName, sourceName } = props;
  const { t } = useTranslation();
  const { values, setValues } = useFormikContext<{ [key: string]: string }>();

  const handleChange = (value: string) => {
    setValues({
      ...values,
      interestGroupId: value,
      [groupName]: value,
      [sourceName]: '',
    });
  };

  return (
    <Select
      {...props}
      onChange={handleChange}
      options={getInterestSourceGroups(t)}
    />
  );
};

export default InterestGroupSelect;
