import React from 'react';

import { useFormikContext } from 'formik';

import Select, { SelectProps } from '@components/Formik/Select';

import { getListSource } from '@domain/leads/join-form/interestList';
import { InterestSource } from '@domain/leads/join-form/types';

import { useTranslation } from '@external/react-i18next';

import { Scalars } from '@typings/graphql';

type Props = Omit<SelectProps, 'options'> & {
  interestGroupId: Scalars['String'];
  name: Scalars['String'];
};

const InterestSourceSelect: React.FC<Props> = props => {
  const { name, interestGroupId } = props;
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();

  const generateOptions = (
    interestGroupId: string,
    sources: Record<string, InterestSource[]>
  ) => {
    return sources[interestGroupId].map(({ name, id }) => ({
      label: name,
      value: id,
    }));
  };

  const getInterestSourceSelect = (interestGroupId: string) => {
    const listInterestSource: Record<string, InterestSource[]> = getListSource(
      t
    );

    return generateOptions(interestGroupId, listInterestSource);
  };

  const handleChange = (value: string) => {
    setFieldValue(name, value);
  };

  return (
    <Select
      {...props}
      onChange={handleChange}
      options={getInterestSourceSelect(interestGroupId)}
    />
  );
};

export default InterestSourceSelect;
