import React, { ReactElement, useEffect } from 'react';

import Loading from '@components/Loading';

import { useFetchMembershipsHistory } from '@repositories/leads/hooks';

import { localizedNavigate } from '@utils/localized-navigate';

import { useAppConfig } from '@hooks/appConfig';

type Props = {
  redirectTo: string;
  children: ReactElement;
};

// This component will redirect Active/Historian member to existing member & others to JoinForm
const AuthorizedRoute = ({ redirectTo, children }: Props) => {
  const { user } = useAppConfig();

  const [
    fetchMembershipsHistory,
    { data, loading },
  ] = useFetchMembershipsHistory();

  useEffect(() => {
    if (user?.individualId) {
      fetchMembershipsHistory({
        variables: {
          id: user?.individualId,
        },
      });
    }
  }, [user, children]);

  if (loading) {
    return <Loading />;
  }

  // checking whether user is Active/Historic member
  // If user is Active/Historic member he would have associated with some club
  // and so the getMembershipHistory - History and current membership records will not be empty
  if (user?.isLoggedIn && data && data?.getMembershipHistory.length > 0) {
    localizedNavigate(redirectTo);
  }

  return children as ReactElement;
};

export default AuthorizedRoute;
