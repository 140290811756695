import React from 'react';

import NotFound from '@components/Routing/NotFound';
import AuthorizedRoute from '@presenters/web/components/Leads/JoinForm/auth/AuthorizedRoute';
import { LandingPage } from '@presenters/web/pages/Leads/JoinForm';

import { FEATURE_LEADS_JOIN_FORMS, isEnabled } from '@utils/features';

const JoinFormPage: React.FC = () => {
  const isLeadJoinFormEnabled = isEnabled(FEATURE_LEADS_JOIN_FORMS);
  if (!isLeadJoinFormEnabled) {
    return <NotFound />;
  }

  return (
    <AuthorizedRoute redirectTo="/join-form/existing-members">
      <LandingPage />
    </AuthorizedRoute>
  );
};

export default JoinFormPage;
