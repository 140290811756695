import React, { useState } from 'react';

import { Button } from '@components/Button';
import { OneColumn } from '@components/Layouts/OneColumn';
import Header from '@presenters/web/components/Leads/JoinForm/Header';
import PageSteps from '@presenters/web/components/Leads/Steps/';
import {
  ClubMeetingPreferences,
  ContactInformation,
} from '@presenters/web/pages/Leads/JoinForm';

import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

const LandingPage: React.FC = () => {
  enum JoinPageSteps {
    CONTACTINFO,
    CLUBMEETING,
    THANKYOU,
  }
  const { t } = useTranslation();

  const [step, setStep] = useState<JoinPageSteps>(JoinPageSteps.CONTACTINFO);

  const handleBackArrowClick = (pageStep: JoinPageSteps) => setStep(pageStep);

  const renderFormSteps = (step: JoinPageSteps) => {
    switch (step) {
      case JoinPageSteps.CONTACTINFO:
        return (
          <PageSteps step={1} total={2}>
            <ContactInformation
              submitHandler={() => setStep(JoinPageSteps.CLUBMEETING)}
            />
          </PageSteps>
        );
      case JoinPageSteps.CLUBMEETING:
        return (
          <PageSteps
            step={2}
            total={2}
            backHandler={() => handleBackArrowClick(JoinPageSteps.CONTACTINFO)}
          >
            <ClubMeetingPreferences
              submitHandler={() => setStep(JoinPageSteps.THANKYOU)}
            />
          </PageSteps>
        );
      case JoinPageSteps.THANKYOU:
        return (
          <div className="max-w-sm mb-20 mt-8">
            <Button
              type="submit"
              full
              clickHandler={() => localizedNavigate('/home')}
            >
              {t('join-form-thank-you.page.homepage-button', 'Go to Homepage')}
            </Button>
          </div>
        );
      default:
        return null;
    }
  };
  const hideInfo: boolean = step === JoinPageSteps.CLUBMEETING;

  const title =
    step === JoinPageSteps.THANKYOU
      ? t('join-form.title_thankyou', 'Thank you!')
      : t('join-form.title_for_all_page', 'Get Involved');

  const info =
    step === JoinPageSteps.THANKYOU
      ? t(
          'join-form-thank-you.new-title.info',
          'We’ll send this to clubs in your area so they can contact you directly.'
        )
      : t(
          'join-form.title.info',
          'By completing the following information, you will be taking the first step to becoming a member. We will use this information to connect you with a club that best matches your interests.'
        );

  return (
    <OneColumn>
      <Header showInfo={!hideInfo} title={title} info={info} />
      {renderFormSteps(step)}
    </OneColumn>
  );
};

export default LandingPage;
