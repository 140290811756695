import React from 'react';

import InterestGroupSelect from './SelectInterest/InterestGroupSelect';
import InterestSourceSelect from './SelectInterest/InterestSourceSelect';

import { useTranslation } from '@external/react-i18next';

const HowDidYouHearAbout: React.FC<{ interestGroupId: string }> = ({
  interestGroupId,
}) => {
  const { t } = useTranslation();
  const interestGroupProps = {
    label: t('join-form.interest-group', 'How did you hear about Rotary?'),
    id: 'join-form-interest-group',
    name: 'join-form-interest-group',
    sourceName: 'join-form-interest-source',
    required: false,
    placeholder: 'Select...',
  };
  const interestSourceProps = {
    label: '',
    id: 'join-form-interest-source',
    name: 'join-form-interest-source',
    displayLabelByDefault: false,
    labelHidden: false,
    required: false,
    placeholder: 'Select...',
  };
  const showInterestSource: boolean =
    interestGroupId !== '' && interestGroupId !== 'toastmasters';
  return (
    <>
      <InterestGroupSelect {...interestGroupProps} />
      {showInterestSource && (
        <InterestSourceSelect
          {...interestSourceProps}
          interestGroupId={interestGroupId}
        />
      )}
    </>
  );
};

export default HowDidYouHearAbout;
