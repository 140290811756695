import React from 'react';

import ReCAPTCHA from 'react-google-recaptcha';

import TextArea from '@components/Formik/TextArea';
import HowDidYouHearAbout from '@presenters/web/components/Leads/HowDidYouHearAbout/HowDidYouHearAbout';
import PrivacyPolicy from '@presenters/web/components/Leads/PrivacyPolicy/PrivacyPolicy';
import Profession from '@presenters/web/components/Leads/Profession/Profession';

import { getLanguageFromURL } from '@utils/query-params';

import { useTranslation } from '@external/react-i18next';

type Props = {
  interestGroupId: string;
  onChangeCaptcha: (reToken: string | null) => void;
  onExpiredCaptcha: () => void;
  recaptchaRef: React.MutableRefObject<null>;
};

const MoreInformation: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { recaptchaRef, onChangeCaptcha, onExpiredCaptcha } = {
    ...props,
  };

  return (
    <>
      <p>
        {t(
          'club-meeting-preference.form.more-info',
          'Please tell us a little bit more about yourself so we can match you with the best club for you.'
        )}
      </p>
      <div className="max-w-lg mt-8">
        <Profession />
        <TextArea
          data-testid="club-meeting-preference-involved"
          name="club-meeting-preference-involved"
          label={t(
            'club-meeting-preference.form.involved',
            'Why do you want to get involved?'
          )}
          characters={250}
          areaclasses="h-40"
        />
        <HowDidYouHearAbout {...props} />
        <div className="max-w-lg mb-6 mobile:mt-6 desktop:mt-12">
          <ReCAPTCHA
            data-testid="mock-v2-captcha-element"
            hl={getLanguageFromURL()}
            ref={recaptchaRef}
            sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY || ''}
            onChange={onChangeCaptcha}
            onExpired={onExpiredCaptcha}
          />
        </div>
        <PrivacyPolicy />
      </div>
    </>
  );
};

export default MoreInformation;
