import React from 'react';

import AddressFields from '@components/Formik/Address';
import Checkbox from '@components/Formik/Checkbox';
import AddressTypeSelect from '@presenters/web/pages/Profile/Edit/ContactInformation/AddressForm/AddressTypeSelect';

import { FormAddressFieldsType, FormFieldsProps } from '@domain/leads/types';

type Props = {
  countryId: string;
  fieldProps: FormFieldsProps;
};

const AddressForm: React.FC<Props> = props => {
  const { countryId, fieldProps } = props;
  return (
    <>
      <div className="max-w-lg mt-8">
        <AddressTypeSelect {...fieldProps.addressTypeSelect} />
        <AddressFields
          countryId={countryId}
          {...(fieldProps.addressFields.formAddress as FormAddressFieldsType)}
        />
        <Checkbox {...fieldProps.interest} />
      </div>
      <hr className="border-t-0 border-b border-gray-300 w-full mt-6 desktop:mt-2 mb-6" />
    </>
  );
};

export default AddressForm;
